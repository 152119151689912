.wrapper {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.field-wrapper {
	display: flex;
	width: 100%;
	border-bottom: 1px dotted black;
}

.left-field {
	width: 60%;
    display: flex;
    align-items: center;
	min-height: 50px;
}

.right-field {
	width: 40%;
	display: flex;
    align-items: center;
	min-height: 50px;
	justify-content: flex-start;
}