
.header {
	background-color: #007bff;
	color: white;
	padding: 20px;
	text-align: center;
	width: 100%;
	margin-bottom: 30px
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.search-bar-wrapper {
	width: 40%;
    margin-left: auto;
    margin-right: auto;
}
.search-bar {
	width: 100%;
	border: 3px solid #00B4CC;
	padding: 5px;
	height: 20px;
	border-radius: 5px;
	outline: none;
  }

  .search-button {
	width: 40px;
	height: 36px;
	border: 1px solid #00B4CC;
	background: #00B4CC;
	text-align: center;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	font-size: 20px;
  }
