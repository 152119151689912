

body {
	@font-face {
		font-family: "Regular";   /*Can be any text*/
		src: local("MaidenOrange"),
		  url("./fonts/MaidenOrange-Regular.ttf") format("truetype");
	  }

	font-family: MaidenOrange;
	margin: 0;
	padding: 0;
}