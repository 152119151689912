
.server {
	border: 1px solid #ddd;
	border-radius: 10px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	margin: 20px;
	padding: 20px;
	width: 300px;
	height: 500px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.label {
	text-align: center;
}

.server-off {
	background-color: #EBEBE4;
}

.button {
	appearance: none;
	background-color: #2ea44f;
	border: 1px solid rgba(27, 31, 35, .15);
	border-radius: 6px;
	box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	padding: 6px 16px;
	position: relative;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	vertical-align: middle;
	white-space: nowrap;
	margin-top: 20px;
}

.button:focus:not(:focus-visible):not(.focus-visible) {
	box-shadow: none;
	outline: none;
}

.button:hover {
	background-color: #2c974b;
	cursor: pointer;
}

.button:focus {
	box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
	outline: none;
}

.button-disabled {
	background-color: grey;
	border-color: rgba(27, 31, 35, .1);
	color: black;
	cursor: default;
}

.button:active {
	background-color: #298e46;
	box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}