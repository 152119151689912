.tab {
	width: 600px;
	margin: 0 auto;
  }

  .tab-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
  }

  .tab-item {
	cursor: pointer;
	padding: 10px;
	border: 1px solid #ccc;
	flex:0 0 auto;
	border-radius: 10px;
	margin: 10px;
  }

  .tab-item:hover {
	background-color: #f5f5f5;
  }

  .tab-item.active {
	background-color: #004C98;
	color: white;
	border-bottom: 1px solid #fff;
  }

  .tab-content {
	padding: 10px;
	min-height: 200px;
	text-align: center;
	width: 25%;
  }


.server-details {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
  }

  .server-details h1 {
	font-size: 70px;
	margin-bottom: 10px;
  }

  .server-details h2 {
	font-size: 24px;
	margin-bottom: 10px;
  }

  .server-details p {
	font-size: 16px;
	margin-bottom: 5px;
  }

  .server-details .status {
	font-weight: bold;
	color: green;
  }

  .server-details .red {
	font-weight: bold;
	color: red;
  }

  .server-details .git-status-up-to-date {
	font-weight: bold;
	color: green;
  }

  .server-details .git-status-not-up-to-date {
	font-weight: bold;
	color: red;
  }

  .server-details form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin-top: 20px;
  }

  .server-details form div {
	margin-bottom: 10px;
  }

  .server-details form label {
	display: block;
	font-size: 16px;
	margin-bottom: 5px;
  }

  .server-details form input {
	font-size: 16px;
	padding: 5px;
	border: 1px solid #ccc;
  }

  .server-details button {
	font-size: 16px;
	padding: 5px;
	margin-top: 10px;
	background-color: #007bff;
	color: #fff;
	border: none;
	cursor: pointer;
  }

  .server-details button:hover {
	background-color: #0069d9;
  }
